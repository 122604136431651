import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import LazyLoad from 'components/LazyLoad/LazyLoad';
import styles from './LandingWhyUseTwineSection.module.scss';

export default class LandingWhyUseTwineSection extends Component {
  static propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    seeMore: PropTypes.bool,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { image, title, subtitle, seeMore, ...rest } = this.props;

    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 50}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <Row className={styles.section} {...rest}>
          <Col
            className={styles.image + (visible ? '' : ' ' + styles.hide)}
            xs={12}
            sm={6}
          >
            <LazyLoad>
              <img alt="Twine" src={image} />
            </LazyLoad>
          </Col>
          <Col
            className={styles.text + (visible ? '' : ' ' + styles.hide)}
            xs={12}
            sm={6}
          >
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.subtitle}>{subtitle}</p>
            {seeMore && (
              <p>
                <Link to="/whyusetwine">See more reasons ></Link>
              </p>
            )}
          </Col>
        </Row>
      </VisibilitySensor>
    );
  }
}
