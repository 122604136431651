import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Button from 'react-bootstrap/lib/Button';
import VisibilitySensor from 'react-visibility-sensor';
import styles from './LandingGetStarted.module.scss';

export default class LandingGetStarted extends Component {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
  };

  static defaultProps = {
    title:
      'Stand out from the competition. Start your project with Twine today',
    link: '/post-a-job',
    linkText: 'Get started',
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { className, title, link, linkText, gradient, ...rest } = this.props;

    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 50}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={
            (gradient
              ? styles.gradient +
                ' ' +
                (gradient === 'left'
                  ? styles.gradientLeft
                  : styles.gradientRight)
              : styles.noGradient) + (className ? ' ' + className : '')
          }
          {...rest}
        >
          <div className={styles.container}>
            <h2 className={styles.title + (visible ? '' : ' ' + styles.hide)}>
              {title}
            </h2>
            <Button
              bsSize="large"
              bsStyle="success"
              className={styles.button + (visible ? '' : ' ' + styles.hide)}
              href={link}
            >
              <strong>{linkText}</strong>
            </Button>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
