import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import VisibilitySensor from 'react-visibility-sensor';
import LandingWhyUseTwineSection from 'components/LandingPage/LandingWhyUseTwineSection/LandingWhyUseTwineSection';
import styles from './LandingWhyUseTwine.module.scss';
import creativeProfessionals from './creative_professionals.png';
import growth from './growth.png';
import postProject from './post_project.png';
import allContent from './all_content.png';
import portfolio from './portfolio.png';
import scale from './scale.png';
import helpEveryStep from './help_every_step.png';
import payments from './payments.png';

export default class LandingWhyUseTwine extends Component {
  static propTypes = {
    title: PropTypes.string,
    className: PropTypes.string,
    sections: PropTypes.array,
    seeMore: PropTypes.bool,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
    usersCount: PropTypes.string,
  };

  static defaultProps = {
    title: 'Why use Twine',
    sections: [1, 2, 3, 4, 5, 6, 7, 8],
    seeMore: false,
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const {
      title,
      className,
      sections,
      seeMore,
      gradient,
      usersCount,
      ...rest
    } = this.props;

    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={
            (gradient
              ? styles.gradient +
                ' ' +
                (gradient === 'left'
                  ? styles.gradientLeft
                  : styles.gradientRight)
              : styles.noGradient) + (className ? ' ' + className : '')
          }
          {...rest}
        >
          <div className={styles.container}>
            {title ? (
              <h2 className={styles.title + (visible ? '' : ' ' + styles.hide)}>
                {title}
              </h2>
            ) : null}
            <Grid className={styles.sections}>
              {sections.indexOf(1) !== -1 && (
                <LandingWhyUseTwineSection
                  image={creativeProfessionals}
                  title="Companies who are making diversity a priority."
                  subtitle="Set a preference for diversity when posting your project and we will help you diversify your team with our diverse global talent."
                  seeMore={seeMore}
                />
              )}
              {sections.indexOf(2) !== -1 && (
                <LandingWhyUseTwineSection
                  image={growth}
                  title="Companies that need specific creative and digital skills."
                  subtitle={`No matter what skill or roles you require, our global network of over ${usersCount} freelancers are here to support you.`}
                  seeMore={seeMore}
                />
              )}
              {sections.indexOf(3) !== -1 && (
                <LandingWhyUseTwineSection
                  image={allContent}
                  title="Hire on Location."
                  subtitle="If you have a requirement for freelancers in a specific country or city, Twine can help you. Video work, acting or in-house freelancers."
                  seeMore={seeMore}
                />
              )}
              {sections.indexOf(4) !== -1 && (
                <LandingWhyUseTwineSection
                  image={postProject}
                  title="Cheaper than the competition."
                  subtitle="Post a project or job with no upfront cost. Twine's pricing is transparent and cheaper than any recruiters. The antidote to recruitment."
                  seeMore={seeMore}
                />
              )}
              {sections.indexOf(5) !== -1 && (
                <LandingWhyUseTwineSection
                  image={portfolio}
                  title="Browse portfolios to make your decision easier."
                  subtitle="All Twine's freelancers have a portfolio to make it quicker to find the perfect person to match your needs."
                  seeMore={seeMore}
                />
              )}
              {sections.indexOf(6) !== -1 && (
                <LandingWhyUseTwineSection
                  image={scale}
                  title="AI dataset creation."
                  subtitle="Twine's global network of freelancers can build large audio and video datasets to help reduce machine learning bias."
                  seeMore={seeMore}
                />
              )}
              {sections.indexOf(7) !== -1 && (
                <LandingWhyUseTwineSection
                  image={helpEveryStep}
                  title="Managed service."
                  subtitle="Twine's managed Enterprise service can help with more specific requirements or large complex projects."
                  seeMore={seeMore}
                />
              )}
              {sections.indexOf(8) !== -1 && (
                <LandingWhyUseTwineSection
                  image={payments}
                  title="Security."
                  subtitle="Use our escrow so you can safely pay for a job, knowing you will get the end result, or your money back."
                  seeMore={seeMore}
                />
              )}
            </Grid>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
