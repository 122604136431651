import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import VisibilitySensor from 'react-visibility-sensor';
import LazyLoad from 'components/LazyLoad/LazyLoad';
import pressBbc from 'static/images/landing-page/press/bbc.png';
import pressFox from 'static/images/landing-page/press/fox.png';
import pressTelegraph from 'static/images/landing-page/press/telegraph.png';
import pressTechcrunch from 'static/images/landing-page/press/techcrunch.png';
import pressTheDrum from 'static/images/landing-page/press/the-drum.png';
import pressHuffingtonPost from 'static/images/landing-page/press/huff-post.png';
import styles from './LandingPressSection.module.scss';

export default class LandingPressSection extends Component {
  static propTypes = {
    className: PropTypes.string,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
  };

  static defaultProps = {
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      visible: false,
    };
  }

  render() {
    const { gradient, className, ...rest } = this.props;

    const { visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 50}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={
            (gradient
              ? styles.gradient +
                ' ' +
                (gradient === 'left'
                  ? styles.gradientLeft
                  : styles.gradientRight)
              : styles.noGradient) + (className ? ' ' + className : '')
          }
          {...rest}
        >
          <div className={styles.container}>
            <Grid>
              <Row>
                <Col
                  className={
                    styles.imageContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={6}
                  md={2}
                >
                  <LazyLoad height={50}>
                    <img src={pressBbc} alt="BBC" style={{ top: '8px' }} />
                  </LazyLoad>
                </Col>
                <Col
                  className={
                    styles.imageContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={6}
                  md={2}
                >
                  <LazyLoad height={50}>
                    <img src={pressFox} alt="Fox" style={{ top: '2px' }} />
                  </LazyLoad>
                </Col>
                <Col
                  className={
                    styles.imageContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={6}
                  md={2}
                >
                  <LazyLoad height={50}>
                    <img
                      src={pressTelegraph}
                      alt="The Telegraph"
                      style={{ top: '15px' }}
                    />
                  </LazyLoad>
                </Col>
                <Col
                  className={
                    styles.imageContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={6}
                  md={2}
                >
                  <LazyLoad height={50}>
                    <img
                      src={pressTechcrunch}
                      alt="TechCrunch"
                      style={{ top: '2px' }}
                    />
                  </LazyLoad>
                </Col>
                <Col
                  className={
                    styles.imageContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={6}
                  md={2}
                >
                  <LazyLoad height={50}>
                    <img
                      src={pressTheDrum}
                      alt="The Drum"
                      style={{ top: '4px' }}
                    />
                  </LazyLoad>
                </Col>
                <Col
                  className={
                    styles.imageContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={6}
                  md={2}
                >
                  <LazyLoad height={50}>
                    <img src={pressHuffingtonPost} alt="Huffington Post" />
                  </LazyLoad>
                </Col>
              </Row>
            </Grid>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}
