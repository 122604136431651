import PropTypes from 'prop-types';
import React from 'react';
import Modal from 'components/Modal/Modal';
import styles from './ExplainerVideoModal.module.scss';

const ExplainerVideoModal = ({ show, onExit }) => {
  return (
    <Modal show={show} onExit={onExit} modalClassName={styles.modal}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingBottom: '56.25%',
        }}
      >
        <iframe
          src="https://www.youtube-nocookie.com/embed/htD8xdmilxI?autoplay=1&controls=0&showinfo=0&rel=0"
          allowFullScreen
          frameBorder="0"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    </Modal>
  );
};

ExplainerVideoModal.propTypes = {
  show: PropTypes.bool,
  onExit: PropTypes.func.isRequired,
};

export default ExplainerVideoModal;
