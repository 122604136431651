import PropTypes from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Grid from 'react-bootstrap/lib/Grid';
import Row from 'react-bootstrap/lib/Row';
import Col from 'react-bootstrap/lib/Col';
import { Link } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor';
import SvgIcon from 'components/SvgIcon/SvgIcon';
import ExplainerVideoModal from 'components/LandingPage/ExplainerVideoModal/ExplainerVideoModal';
import styles from './LandingHowItWorks.module.scss';
import howItWorksDesktop from './how_it_works_desktop.png';
import tasterVideo from './taster.mp4';
import LazyLoad from 'components/LazyLoad/LazyLoad';

class LandingHowItWorks extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    gradient: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.oneOf(['left', 'right']),
    ]),
  };

  static defaultProps = {
    gradient: false,
  };

  constructor() {
    super();

    this.state = {
      showExplainer: false,
      visible: false,
    };
  }

  attachCustomAttributes(domNode) {
    if (domNode) {
      domNode.setAttribute('playsinline', true);
    }
  }

  render() {
    const { className, gradient, ...rest } = this.props;

    const { showExplainer, visible } = this.state;

    return (
      <VisibilitySensor
        partialVisibility
        delayedCall
        minTopValue={visible ? 0 : 100}
        active={!visible}
        onChange={(isVisible) => this.setState({ visible: isVisible })}
      >
        <div
          className={
            (gradient
              ? styles.gradient +
                ' ' +
                (gradient === 'left'
                  ? styles.gradientLeft
                  : styles.gradientRight)
              : styles.noGradient) + (className ? ' ' + className : '')
          }
          {...rest}
        >
          <div className={styles.container}>
            <h2 className={styles.title + (visible ? '' : ' ' + styles.hide)}>
              How it works
            </h2>
            <Grid>
              <Row>
                <Col className={styles.textContainer} xs={12} sm={6}>
                  <div className={styles.points}>
                    <p className={!visible ? styles.hide : ''}>
                      <span className={styles.numbers}>1</span> Post up your job
                      requirements
                    </p>
                    <p className={!visible ? styles.hide : ''}>
                      <span className={styles.numbers}>2</span> Twine finds you
                      the perfect match
                    </p>
                    <p className={!visible ? styles.hide : ''}>
                      <span className={styles.numbers}>3</span> Discuss the job
                      with these freelancers
                    </p>
                  </div>
                  <Link
                    to="/howitworks"
                    className={styles.cta + (visible ? '' : ' ' + styles.hide)}
                  >
                    <Button bsSize="lg" className="btn-hollow">
                      Read more
                    </Button>
                  </Link>
                </Col>
                <Col
                  className={
                    styles.videoContainer + (visible ? '' : ' ' + styles.hide)
                  }
                  xs={12}
                  sm={6}
                  onClick={() => this.setState({ showExplainer: true })}
                >
                  <LazyLoad>
                    <img src={howItWorksDesktop} alt="Twine" />
                  </LazyLoad>
                  <LazyLoad>
                    <video
                      controls={false}
                      autoPlay
                      loop
                      muted
                      ref={this.attachCustomAttributes}
                    >
                      <source src={tasterVideo} type="video/mp4" />
                      Sorry, this video is not supported.
                    </video>
                  </LazyLoad>
                  <div className={styles.playButton}>
                    <SvgIcon icon="play" size="50" color="#fff" />
                  </div>
                </Col>
              </Row>
            </Grid>
          </div>
          <ExplainerVideoModal
            show={showExplainer}
            onExit={() => this.setState({ showExplainer: false })}
          />
        </div>
      </VisibilitySensor>
    );
  }
}

export default LandingHowItWorks;
